import PickAPlan from 'aa/vue/components/PickAPlan';
import Step from 'models/steps/Step';
import CheckoutOptions from 'aa/vue/models/CheckoutOptions';
import Product from 'aa/vue/models/Product';
import { PAGE_TYPES } from 'aa/vue/constants/aaTrackingConstants';
import Feature, { PICK_A_PLAN_COPY_FLEXIBILITY_ENABLED } from 'aa/helpers/featureConstants';

export default class Plan extends Step {
    path = '/account/signup/pickplan/';
    name = 'PLAN';
    component = PickAPlan;
    requireAuth = false;
    requireNonAuth = false;
    countStep = true;

    /**
     * @param from
     * @returns {Promise<[unknown, unknown]>}
     */
    checkAccess(from) {
        return Promise.all([super.checkAccess(from), this._store.dispatch('plan/fetchPlans')]);
    }

    getFlowTag() {
        if (
            this._store.getters['flow/hasDefaultCampaign'] &&
            Feature.enabled(PICK_A_PLAN_COPY_FLEXIBILITY_ENABLED)
        ) {
            return this._store.getters['flow/getCampaignPageAttributeTag'];
        }

        return super.getFlowTag();
    }

    getPageAttributePromoTag() {
        if (this._store.getters.featureIsActive(PICK_A_PLAN_COPY_FLEXIBILITY_ENABLED)) {
            const singlePromoOffer = this._store.getters['plan/singleCouponOffer'];

            if (singlePromoOffer) {
                return `promo_${singlePromoOffer}`;
            }
        }

        return null;
    }

    /**
     * @param to
     * @returns {{path: *, name: *}}
     */
    getTrackingArgs(to) {
        let args = super.getTrackingArgs(to);

        args.pageTitle = 'Paramount+ Subscription Plans and Pricing';
        args.pageType = this.getPartnerTrackingParams().pageType;
        args.partnerBundle = this.getPartnerTrackingParams().partnerBundle;

        return args;
    }

    getPartnerTrackingParams() {
        return {
            pageType: this._store.getters.isPartnerBundle
                ? PAGE_TYPES.PICK_A_PLAN_BUNDLE
                : PAGE_TYPES.PICK_A_PLAN_DEFAULT,
            partnerBundle: this._store.getters.isPartnerBundle ? Product.ADDON_PARTNER_BUNDLE : '',
        };
    }

    /**
     * Before enter hook
     *
     * @param to
     * @param from
     * @param next
     * @returns {Promise<void>}
     */
    async beforeEnter(to, from, next) {
        const selectedPlan = CheckoutOptions.get()?.selectedPlan;

        // set selected plan from upsell and redirect to next step
        if (selectedPlan !== null) {
            const store = this._store;

            // save selected plan on plan store
            store.dispatch('plan/updateSelectedPlan', selectedPlan).then(() => {
                // remove selected plan from checkout options
                CheckoutOptions.get().save({
                    selectedPlan: null,
                });

                // redirect to next step
                next(store.getters['flow/getNextStep']);
            });
        } else {
            await super.beforeEnter(to, from, next);
        }
    }
}
