<template>
    <div class="multi-sub-plan -headline" :class="{ '-switch': isSwitchPlan }">
        <steps-header
            v-bind="$props"
            :hasContent="!isMobile"
            :overrideShouldDisplaySteps="isSwitchPlan"
            :overrideStepNumber="overrideStepNumber"
            :overrideCountedSteps="overrideCountedSteps">
            <steps-header-message
                v-if="!isMobile"
                :header="activeCadenceOptions.sub_heading"
                :headerClasses="['sub-heading--headline']"></steps-header-message>
        </steps-header>
        <div
            class="multi-sub-plan__sub-heading sub-heading--headline"
            data-ci="sub-heading--headline"
            v-html="activeCadenceOptions.sub_heading"
            v-if="isMobile"></div>
        <div class="multi-sub-plan__container">
            <div class="multi-sub-plan__flex -cadence">
                <MultiSubBox
                    v-for="(plan, idx) in activeCadenceOptions.plans"
                    :key="idx"
                    class="-cadence"
                    :class="{
                        '-animate': isSelecting && active === idx,
                        '-switch-plan': isSwitchPlan,
                    }"
                    :idx="idx"
                    :plan="plan"
                    :is-active="active === idx"
                    :is-current="isCurrentPlan(plan.plan)"
                    :is-downgrade="isDowngrade(plan)"
                    :is-switch-plan="isSwitchPlan"
                    :same-billing-timeframe="sameBillingTimeframe()"
                    :is-upgrade="isUpgrade(plan)"
                    :is-mobile="isMobile"
                    :is-disabled="isDisabled(plan.plan)"
                    :is-cadence-step="true"
                    @planSelected="handlePlanSelected"
                    @notify="notify"
                    pickAPlanTracking="cadence"
                    data-ci="cadence-content"
                    :data-plan-option="`${plan.plan.planTier}-${plan.plan.planType}`"
                    :data-plan-card="`${plan.planType}-cycle`"
                    :isPlanDescriptionEmpty="isEmptyCadencesDescriptionCopy"
                    :cadenceHasDiscountedSubtotals="cadenceHasDiscountedSubtotals"
                    @cadence-has-discounted-subtotals="cadenceHasDiscountedSubtotals = $event"
                    :max-plan-info-height="maxPlanInfoHeight"
                    @max-plan-info-height="setMaxPlanInfoHeight($event)"
                    :cadences-has-additional-info="cadencesHaveAdditionalInfo"
                    :has-promo-for-monthly-cadence-only="hasPromoForMonthlyCadenceOnly()"
                    @cadences-has-additional-info="
                        cadencesHaveAdditionalInfo = $event
                    "></MultiSubBox>
            </div>
            <div class="multi-sub-plan__legal">
                <MultiSubBoxLegal
                    v-for="(plan, idx) in activeCadenceOptions.plans"
                    :key="idx"
                    :is-switch-plan="isSwitchPlan"
                    :is-upgrade="isUpgrade(plan)"
                    :same-billing-timeframe="sameBillingTimeframe()"
                    :is-current="isCurrentPlan(plan.plan)"
                    :plan="plan"></MultiSubBoxLegal>
            </div>
        </div>
        <div
            class="trial"
            v-if="activeCadenceOptions.trial_period && activeCadenceOptions.trial_description">
            <div class="trial__icon"></div>
            <div class="trial__header">{{ activeCadenceOptions.trial_period }}</div>
            <div class="trial__description">{{ activeCadenceOptions.trial_description }}</div>
        </div>

        <div class="multi-sub-plan__legal-mobile">
            <LearnMoreLegal
                v-if="legal"
                :legal="legal"
                :planType="this.getPlanType"></LearnMoreLegal>
        </div>
        <div v-if="isMobile" :class="proceedButtonClasses">
            <ButtonCta :buttonConfig="getButtonConfig"></ButtonCta>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapState } from 'vuex';

    import ButtonCta from 'atoms/ButtonCta';
    import MultiSubBox from 'aa/vue/components/multisubplanpicker/MultiSubBox';
    import MultiSubBoxLegal from 'aa/vue/components/multisubplanpicker/MultiSubBoxLegal';
    import LearnMoreLegal from 'aa/vue/components/multisubplanpicker/LearnMoreLegal';
    import StepsHeader from 'aa/vue/components/StepsHeader';
    import StepsHeaderMessage from 'aa/vue/components/StepsHeaderMessage';
    import Breakpoints from 'helpers/breakpoints';

    import switchPlanMixin from 'aa/vue/components/multisubplanpicker/switchPlanMixin';
    import commonMixins from 'aa/vue/plugins/account/commonMixins';
    // START TEST PLOCTOPLUS-1298: google captcha
    import captchaMixin from 'aa/vue/plugins/captchaMixin';
    // END TEST PLOCTOPLUS-1298: google captcha
    import NotificationBarModel from 'aa/vue/models/NotificationBarModel';

    export default {
        name: 'Cadence',
        components: {
            StepsHeader,
            StepsHeaderMessage,
            ButtonCta,
            MultiSubBox,
            MultiSubBoxLegal,
            LearnMoreLegal,
        },
        mixins: [
            commonMixins,
            switchPlanMixin,
            // START TEST PLOCTOPLUS-1298: google captcha
            captchaMixin,
            // END TEST PLOCTOPLUS-1298: google captcha
        ],
        props: {
            stepsHeader: {
                type: String,
                default: null,
            },
            stepsCounter: {
                type: String,
                default: null,
            },
        },
        data() {
            return {
                breakpoints: new Breakpoints(),
                // START TEST PLOCTOPLUS-1298: google captcha
                captchaSolved: false,
                captchaUseNonDefaultCallBack: true,
                captchaNonDefaultCallBack: this.submitOnClicked,
                captchaAction: 'FORM_SWITCH_PLAN',
                // END TEST PLOCTOPLUS-1298: google captcha
                enhancedPaymentSummaryEnabled: false,
                isMobile: false,
                isSelecting: false,
                maxPlanInfoHeight: 0,
                cadenceHasDiscountedSubtotals: false,
                cadencesHaveAdditionalInfo: false,
            };
        },
        methods: {
            ...mapActions('plan', ['setPlanType', 'setPlanTier']),
            handlePlanSelected(data) {
                const { idx, submit, displayText } = data;
                this.active = idx;
                if (submit) {
                    if (this.isSwitchPlan && this.enhancedPaymentSummaryEnabled) {
                        this.isSelecting = true;
                        setTimeout(() => {
                            this.isSelecting = false;
                            this.$router.push({ name: 'AccountSummary' });
                        }, 500);
                    } else if (this.isSwitchPlan) {
                        this.isSelecting = true;
                        setTimeout(() => {
                            this.isSelecting = false;
                            this.submitOnClicked(displayText);
                        }, 500);
                    } else {
                        // Purchase flow.
                        this.$router.push(this.$store.getters['flow/getNextStep']);
                    }
                }
            },
            updateBreakpoint() {
                this.isMobile =
                    this.breakpoints.isMobilePortrait() || this.breakpoints.isMobileLandscape();
            },
            switchPlanPrompt() {
                this.openModal('SwitchPlanPromptModal');
            },
            notify(message) {
                this.openNotification(
                    new NotificationBarModel({
                        message: this.$getLocale(message),
                    }),
                );
            },
            setMaxPlanInfoHeight(height) {
                this.maxPlanInfoHeight = Math.max(this.maxPlanInfoHeight, height);
            },
            updateTierIfUndefined() {
                if (!this.getPlanTier) {
                    const tiers = this.getTiers;
                    if (!tiers || tiers.length === 0) {
                        return;
                    }
                    if (tiers.length === 1) {
                        this.setPlanTier(tiers[0].planTier);
                    } else {
                        try {
                            const tierFromSession = JSON.parse(sessionStorage.getItem('planTier'));
                            this.setPlanTier(tierFromSession || tiers[0].planTier);
                        } catch (e) {
                            console.log('ERROR', e);
                            const activeIdx = this.active > -1 ? this.active : 0;
                            this.setPlanTier(tiers[activeIdx].planTier);
                        }
                    }
                }
            },
            isUpgrade(plan) {
                return plan?.isUpgrade;
            },
            isDowngrade(plan) {
                return plan?.isDowngrade;
            },
            isDisabled(plan) {
                if (plan.allowedMigration != undefined) {
                    return !plan.allowedMigration;
                }
                return false;
            },
            isCurrentPlan(plan) {
                return plan?.switchDirection === 'current';
            },
            areAllCardsUpgrade() {
                return this.activeCadenceOptions.plans.every((plan) => this.isUpgrade(plan));
            },
            areAllCardsDowngrade() {
                return this.activeCadenceOptions.plans.every((plan) => this.isDowngrade(plan));
            },
            sameBillingTimeframe() {
                return this.areAllCardsUpgrade() || this.areAllCardsDowngrade();
            },
            // Return true when promo only applies for monthly plans
            hasPromoForMonthlyCadenceOnly() {
                const hasPromo = (planType) => {
                    return this.activeCadenceOptions.plans.some(
                        (plan) => plan.planType === planType && plan.plan.isValidPromo,
                    );
                };
                const hasMonthlyPromo = hasPromo('monthly');
                const hasAnnualPromo = hasPromo('annual');
                return hasMonthlyPromo && !hasAnnualPromo;
            },
        },
        computed: {
            overrideStepNumber() {
                return this.isSwitchPlan && this.isSwitchToAnnual ? 1 : 2;
            },
            overrideCountedSteps() {
                return this.isSwitchPlan && this.isSwitchToAnnual ? 2 : 3;
            },
            ...mapState('modal', {
                confirmSwitchPlan: (state) => state.confirmSwitchPlan,
            }),
            ...mapGetters('plan', [
                'getPlanType',
                'getPlanTier',
                'getTiers',
                'getLegals',
                'activeCadenceOptions',
                'isSwitchPlan',
                'currentPlan',
                'getSelectedPlan',
                'selectedPlan',
                'isSwitchToAnnual',
                'isEmptyCadencesDescriptionCopy',
            ]),
            ...mapGetters(['featureIsActive']),
            activePlan() {
                return this.activeCadenceOptions.plans.find(
                    (cadence) => this.getPlanType === cadence.planType,
                );
            },
            legal: function () {
                return this.getLegals?.find((legal) => {
                    return legal.tags.find((tag) =>
                        tag.includes(`billing_${this.activeCadenceOptions.planTier}`),
                    );
                });
            },
            active: {
                get() {
                    return this.activeCadenceOptions.plans.findIndex(
                        (cadence) => this.getPlanType === cadence.planType,
                    );
                },
                set(index) {
                    this.setPlanType({
                        planType: this.activeCadenceOptions.plans[index].planType,
                    });
                },
            },
            getButtonConfig: function () {
                const displayText = this.activePlan.plan_is_current
                    ? (this.activePlan.current_plan_submit_button ??
                      this.activePlan.plan_button_current)
                    : (this.activePlan.plan_button ?? this.activePlan.plan_submit_button);

                return {
                    styleClass: `button primary ${this.isActivePlanDisabled ? 'disabled' : ''} ${
                        this.legal?.learn_more_copy.length > 10 ? '-gradient' : ''
                    }`,
                    displayText: displayText,
                    tabIndex: '0',
                    disabled: this.isActivePlanDisabled,
                    event: (event) => {
                        event.stopPropagation();
                        const args = {
                            idx: this.activeCadenceOptions.plans.findIndex(
                                (plan) => this.activePlan.planType === plan.planType,
                            ),
                            submit: true,
                        };
                        this.handlePlanSelected(args);
                    },
                };
            },
            isActivePlanDisabled() {
                return this.isDisabled(this.activePlan.plan) || this.activePlan?.plan_is_current;
            },
            proceedButtonClasses() {
                return [
                    'multi-sub-plan__proceed-button',
                    {
                        '-gradient': this.legal?.learn_more_copy.length > 200,
                    },
                ];
            },
        },
        mounted() {
            this.enhancedPaymentSummaryEnabled =
                this.$store.state.serverData.enhancedPaymentSummaryEnabled;
            this.updateTierIfUndefined();
            // Reset CSS variables on page load
            const root = document.documentElement;
            root.style.setProperty('--plan-box__title-height', 40);
            root.style.setProperty('--plan-box__copy-height', 23);
            root.style.setProperty('--plan-box__price-height', 52);
            // START TEST PLOCTOPLUS-1298: google captcha
            if (this.isSwitchPlan) {
                let pageType = this.$route.params?.isCrossgrade
                    ? 'svod_crossgrade'
                    : 'svod_switch-plan-payment';
                this.$trackingService.trackState({ pageType });
                this.addCaptchaValidation();
            }

            // END TEST PLOCTOPLUS-1298: google captcha
            window.addEventListener('resize', () => {
                requestAnimationFrame(() => {
                    this.updateBreakpoint();
                });
            });
            this.updateBreakpoint();
        },
        watch: {
            confirmSwitchPlan(value, oldValue) {
                if (!oldValue && value) {
                    this.submitOnClicked(
                        this.$store.state.serverData.multiSubPlanAttributes?.SwitchPlanPromptModal
                            ?.switch_prompt_confirm_cta,
                    );
                }
            },
        },
    };
</script>
