<template>
    <div class="order-summary" :class="countryClass">
        <div :class="summaryClassList">
            <template v-if="isPaymentPage">
                <h4 class="order-summary__heading">
                    {{ pageAttr.aa_order_summary_title }}
                </h4>
                <div class="order-summary__plan-link">
                    <router-link
                        :to="getSignupRouterLink"
                        v-if="displayChangePlanLink"
                        class="qt-changelink">
                        <span v-on:click="handleChangePlanLink">
                            {{ pageAttr.aa_order_summary_change_plan_cta }}
                        </span>
                    </router-link>
                </div>
            </template>

            <div class="col" v-html="planLabel"></div>
            <div class="col qt-payment-price">
                {{ this.internationalizedPriceWithUnit }}
                <span v-if="featureAsterisk && !isExSubscriber">*</span>
            </div>

            <template v-if="displayTrialInfo">
                <div class="col">
                    {{ pageAttr.aa_order_summary_trial_label }}
                </div>
                <div class="col qt-trial-price">
                    {{ coupon.trialString || plan.trialString }}
                </div>
            </template>

            <template v-if="coupon.discountString">
                <div class="col">{{ $getLocale('discount') }}</div>
                <div class="col qt-discount-price">
                    {{ coupon.discountString }}
                </div>
            </template>

            <!-- START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX -->
            <UpgradeAnnualToggle
                v-if="isUpgradeAnnualToggle"
                @filterAndSelect="filterPlanAndUpdateSelected"></UpgradeAnnualToggle>
            <UpgradeAnnualTwoPlanTiles
                v-if="isUpgradeAnnualPlanTiles"
                @filterAndSelect="filterPlanAndUpdateSelected"></UpgradeAnnualTwoPlanTiles>
            <!-- END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX -->

            <div class="col col--row-total">
                {{ pageAttr.aa_order_summary_total_label }}
            </div>
            <div class="col col--row-total qt-total-price">
                <span v-show="showSubTotalNow" data-recurly="subtotal_now"></span>
                <span v-show="showSubTotalNext" data-recurly="subtotal_next"></span>
                <span v-show="showIntlZeroPrice">{{ this.internationalizedZeroPrice }}</span>
            </div>
        </div>

        <input type="hidden" data-recurly="plan" :value="plan.recurlyCode" />
        <p class="order-summary__disclaimer" v-html="disclaimerCopyEl"></p>
        <!-- START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX -->
        <p class="order-summary__disclaimer" v-if="showAnnualUpgradeDisclaimer">
            {{ getAdditionalABTestDisclaimer }}
        </p>
        <!-- END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX -->

        <p
            class="order-summary__dynamic-disclaimer"
            v-if="getDynamicDisclaimerCopy"
            v-html="getDynamicDisclaimerCopy"></p>

        <template v-if="isPaymentPage">
            <button
                class="order-summary__coupon-prompt"
                :aria-expanded="couponFieldOpenState ? 'true' : 'false'"
                @click="handleClickCouponPrompt">
                <div class="prompt qt-promo-link" tabindex="0">
                    {{ pageAttr.aa_coupon_field_prompt }}
                </div>
                <div :class="iconCaretClassList"></div>
            </button>

            <transition name="slide-down-fade">
                <div v-if="!$store.state.promo || autoPromoChecked" v-show="couponFieldOpenState">
                    <div class="order-summary__coupon">
                        <InputField
                            :inputConfig="orderSummaryConfig.inputList.couponCode"
                            v-model="form['couponCode']"
                            :class="{ error: coupon.errorMessage }"
                            :keydownHandler="handleEnterKey"></InputField>
                        <InputElm
                            v-show="displayPromoField"
                            ref="planRecurly"
                            :inputConfig="orderSummaryConfig.hiddenInputList.plan" />
                        <input ref="couponRecurly" value="" type="hidden" data-recurly="coupon" />

                        <div class="order-summary__coupon__error qt-err-coupon-code">
                            {{ coupon.errorMessage }}
                        </div>

                        <ButtonCta :buttonConfig="buttonConfig"></ButtonCta>
                    </div>
                </div>
            </transition>
        </template>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { catanAddOnCode } from 'aa/vue/constants/aaBundle';
    import InputElm from 'atoms/InputElm';
    import InputField from 'atoms/InputField';
    import pricePrettyPrint from 'aa/vue/plugins/priceMixin';
    import aarpMixin from 'aa/vue/plugins/aarpMixin.js';
    import ButtonCta from 'atoms/ButtonCta';
    import { OrderSummaryConfig } from 'aa/vue/FormConfig';
    import { decimalFormat } from 'aa/vue/components/account/helpers';
    import WaitingOverlay from 'aa/vue/atoms/WaitingOverlay';
    import {
        AA_ADDONS,
        PRICE_WITH_ASTERISK,
        MULTI_SUB_PLAN_PICKER_ENABLED,
    } from 'helpers/featureConstants';
    import { DateFormatter, getEndDate } from 'aa/helpers/DateFormatter';
    import { sanitizer } from 'apps/plugins/Sanitizer';
    import Domain from 'helpers/Domain';
    import { tracking } from 'services/Tracking';
    // START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
    import UpgradeAnnualToggle from 'aa/vue/components/UpgradeAnnualToggle';
    import UpgradeAnnualTwoPlanTiles from 'aa/vue/components/UpgradeAnnualTwoPlanTiles';
    import Product from 'aa/vue/models/Product';

    // END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX

    export default {
        components: {
            InputElm,
            InputField,
            ButtonCta,
            WaitingOverlay,
            // START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
            UpgradeAnnualToggle,
            UpgradeAnnualTwoPlanTiles,
            // END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
        },

        props: {
            coupon: {
                type: Object,
            },
            couponDisclaimer: {
                type: String,
            },
            couponCheckPending: {
                type: Boolean,
            },
            recurly: {
                type: Object,
            },
            annualPlanOption: {
                type: Boolean,
                default: true,
            },
            formConfig: {
                type: Object,
                default: null,
            },
        },

        mixins: [pricePrettyPrint, aarpMixin],
        data: function () {
            return {
                isPaymentPage: true,
                form: {},
                countryClass: '',
                featureAsterisk: false,
                couponFieldManualOpen: null,
                couponFieldOpenState: null,
                internationalizedPrice: null,
                internationalizedZeroPrice: null,
                internationalizedPriceWithUnit: null,
                orderSummaryConfigDefault: OrderSummaryConfig,
                // START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
                // we need this flag on component level to maintain plan type locally,
                // if user change plan multiples times on payment page then we have to show/hide a/b test UI
                isUserOriginalPlanTypeMonthly: true,
                // START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
            };
        },

        computed: {
            orderSummaryConfig() {
                return this.formConfig || this.orderSummaryConfigDefault;
            },
            ...mapState('pageAttributes', {
                pageAttr: (state) => state.attributes,
            }),

            currentPlan() {
                if (this.$store.getters.featureIsActive(MULTI_SUB_PLAN_PICKER_ENABLED)) {
                    return this.$store.getters['plan/getSelectedPlan'];
                } else {
                    return this.$store.getters['plan/getCurrentPlan'];
                }
            },

            ...mapState('user', {
                isExSubscriber: (state) => state.isExSubscriber,
            }),
            ...mapState(['promo']),

            ...mapState(['autoPromoChecked']),

            exSubWithTrial() {
                return this.isExSubscriber && !!this.coupon.trialString;
            },

            ...mapGetters('user', ['isNoTrialUser']),

            ...mapGetters(['bundleShowtime']),
            // START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
            ...mapGetters({
                upgradeAnnualToggle: 'featureFlags/upgradeAnnualToggle',
                upgradeAnnualPlanTiles: 'featureFlags/upgradeAnnualPlanTiles',
                selectedPlan: 'plan/selectedPlan',
                monthlySelectedAndAnnualAvailable: 'plan/monthlySelectedAndAnnualAvailable',
                annualSelectedAndMonthlyAvailable: 'plan/annualSelectedAndMonthlyAvailable',
            }),
            isUpgradeAnnualPlanTiles() {
                return this.upgradeAnnualPlanTiles && this.isUserOriginalPlanTypeMonthly;
            },
            isUpgradeAnnualToggle() {
                return (
                    this.upgradeAnnualToggle &&
                    this.isUserOriginalPlanTypeMonthly &&
                    (this.canToggleFromMonthlyToAnnual || this.canToggleFromAnnualToMonthly)
                );
            },
            canToggleFromMonthlyToAnnual() {
                return (
                    this.currentPlan?.planType === Product.TYPE_MONTHLY &&
                    this.monthlySelectedAndAnnualAvailable.length > 0
                );
            },
            canToggleFromAnnualToMonthly() {
                return (
                    this.currentPlan?.planType === Product.TYPE_ANNUAL &&
                    this.annualSelectedAndMonthlyAvailable.length > 0
                );
            },
            //END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX

            getSignupRouterLink() {
                var baseLink = '/account/signup/pickplan/';
                var bundleLink = `${baseLink}bundle/${catanAddOnCode().toLowerCase()}/`;

                return this.$store.getters['isPartnerBundle'] ? bundleLink : baseLink;
            },

            displayTrialInfo() {
                if (this.removeTrial) {
                    return false;
                }
                return this.coupon?.trialString || this.plan?.trialString;
            },

            removeTrial() {
                // ex-subs without trial extension or coupon not eligible for free trial
                return (
                    Domain.isDomestic() &&
                    ((this.isNoTrialUser && !this.coupon.trialString) || !this.plan.trial)
                );
            },

            isInternational() {
                return Domain.isInternational();
            },

            isNoTrialUserWithCouponTrial() {
                return this.isNoTrialUser && !!this.coupon.trialString;
            },

            isNoTrialUserWithoutCouponTrial() {
                return this.isNoTrialUser && !this.coupon.trialString;
            },

            hasPromoCode() {
                return this.$store.state.promo == null ? false : true;
            },

            promoSuppressedByShowtimeBundleCopy() {
                if (this.pageAttr.aa_promo_suppressed_message_showtime === undefined) {
                    return '';
                } else {
                    return this.pageAttr.aa_promo_suppressed_message_showtime.replace(
                        '%COUPON_CODE%',
                        this.$store.state.promo,
                    );
                }
            },

            plan() {
                if (this.currentPlan.trialString && this.isNoTrialUser) {
                    this.currentPlan.trialString = null;
                }

                return this.currentPlan;
            },

            planLabel() {
                if (Domain.isInternational()) {
                    const planLabel = this.$store.getters.featureIsActive(
                        MULTI_SUB_PLAN_PICKER_ENABLED,
                    )
                        ? this.$store.getters['plan/getSelectedPlanLabelKey']
                        : this.$store.getters['plan/getPlanLabelKey'];

                    return this.$getLocale(planLabel);
                }

                return this.plan.planTitle;
            },

            displayChangePlanLink() {
                return (
                    this.$store.getters['plan/getPlans'].length > 1 &&
                    !this.$store.getters['flow/isEdu']
                );
            },

            iconCaretClassList() {
                return ['icon', 'icon--caret', this.couponFieldOpenState ? 'icon--caret--up' : ''];
            },

            summaryClassList() {
                const classList = ['order-summary__two-col'];

                // START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
                if (this.isUpgradeAnnualToggle) {
                    classList.push('upgrade-annual-toggle');
                }
                if (this.isUpgradeAnnualPlanTiles) {
                    classList.push('upgrade-plan-tiles');
                }
                // END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX

                return classList;
            },

            couponTrialEndDate() {
                let discount = !this.coupon.recurlyInfo ? null : this.coupon.recurlyInfo.discount;

                if (!discount || discount.type !== 'free_trial') {
                    return null;
                }

                try {
                    let { amount, unit } = discount.trial;
                    let trialEndDate = getEndDate(amount, unit);
                    return DateFormatter.format(trialEndDate, null, CBS.Registry.region.dateLocale);
                } catch (e) {
                    return null;
                }
            },

            trialEndDate() {
                const trialEndDate = new Date(this.plan.trialEndDate);
                const trialEndDateFormatted =
                    this.couponTrialEndDate ||
                    DateFormatter.format(trialEndDate, null, CBS.Registry.region.dateLocale);
                this.$emit('trialEndDateFormatted', trialEndDateFormatted);
                return trialEndDateFormatted;
            },

            trialEndCopy() {
                if (!this.plan.trialUnit || this.removeTrial || this.couponCheckPending) {
                    return '';
                }

                let trialEndEl = `<span id="trial-end-date" data-original="${this.plan.trialEndDate}">
                                        ${this.trialEndDate}</span>`;

                return this.pageAttr.aa_order_summary_trial_end_copy.replace('%d', trialEndEl);
            },
            getDynamicDisclaimerCopy() {
                return sanitizer(
                    this.couponDisclaimer
                        ? this.couponDisclaimer
                        : this.pageAttr.aa_campaign_upsell_disclaimer,
                );
            },

            disclaimerCopyEl() {
                return this.trialEndCopy + ' ' + this.pageAttr.aa_order_summary_tax_disclaimer;
            },

            // START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
            showAnnualUpgradeDisclaimer() {
                return this.isUpgradeAnnualPlanTiles || this.isUpgradeAnnualToggle;
            },
            getAdditionalABTestDisclaimer() {
                return this.pageAttr?.aa_payment_upgrage_annual_additional_disclaimer ?? '';
            },
            // END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX

            buttonConfig() {
                let buttonConfig = OrderSummaryConfig.buttonConfig;

                if (this.isAarpFlow()) {
                    buttonConfig.styleClass.push('disabled');
                }

                return Object.assign(
                    {
                        displayText: this?.pageAttr?.aa_coupon_field_cta ?? this.$getLocale('ok'),
                        event: this.handleClickCouponCTA,
                    },
                    buttonConfig,
                );
            },

            displayPromoField() {
                return this.promo && this.orderSummaryConfig.inputList.couponCode.value;
            },

            showSubTotalNow() {
                return !this.removeTrial || this.isInternational;
            },

            showSubTotalNext() {
                return this.removeTrial && !this.isNoTrialUserWithCouponTrial;
            },

            showIntlZeroPrice() {
                return this.removeTrial && this.isNoTrialUserWithCouponTrial;
            },
        },

        methods: {
            getInternationalizedPricesUsingRecurly() {
                if (this.recurly) {
                    const currentPlan = this.$store.getters['plan/getCurrentPlan'];
                    const { currencySymbol, currencyCode } = this.recurly.Pricing.Checkout();
                    let currentPlanPrice = parseFloat(currentPlan.rawPrice.replace(/,/g, ''));
                    this.internationalizedZeroPrice = `${this.pricePrettyPrint(0, currencySymbol, currencyCode)}`;
                    this.internationalizedPrice = `${this.pricePrettyPrint(
                        currentPlanPrice,
                        currencySymbol,
                        currencyCode,
                    )}`;

                    this.internationalizedPriceWithUnit = `${this.pricePrettyPrint(
                        currentPlanPrice,
                        currencySymbol,
                        currencyCode,
                    )}/${currentPlan.priceUnit}`;

                    this.$emit('found-price', {
                        internationalizedPrice: this.internationalizedPrice,
                        internationalizedPriceWithUnit: this.internationalizedPriceWithUnit,
                    });
                }
            },

            handleClickCouponPrompt(e) {
                e.preventDefault();
                if (this.couponFieldOpenState) {
                    this.manuallyCloseCouponField();
                } else {
                    this.manuallyOpenCouponField();
                }
            },
            setStartingStateOfCouponField() {
                if (this.$store.state.promo && this.orderSummaryConfig.inputList.couponCode.value) {
                    this.couponFieldOpenState = true;
                } else {
                    this.couponFieldOpenState = false;
                }
            },
            resetManualCouponField() {
                this.couponFieldManualOpen = null;
            },
            manuallyOpenCouponField() {
                this.couponFieldManualOpen = true;
                this.couponFieldOpenState = true;
            },

            manuallyCloseCouponField() {
                this.couponFieldManualOpen = false;
                this.couponFieldOpenState = false;
            },
            automaticallyCloseCouponField() {
                if (!this.couponFieldManualOpen) {
                    this.couponFieldOpenState = false;
                }
            },

            handleClickCouponCTA(e) {
                e.preventDefault();
                this.resetManualCouponField();
                const couponCode = this.attachCurrentCouponToForm();
                if (this.form.couponCode?.value !== null && this.form.couponCode?.value !== '') {
                    this.$refs.couponRecurly.value = couponCode;
                } else {
                    //resetting coupon error message and css class
                    this.resetCouponFieldErrorMessage();
                }

                this.$emit('applyCoupon', {
                    plan: this.plan.recurlyCode,
                    couponCode: couponCode,
                });
            },

            handleEnterKey(e) {
                if (e.key === 'Enter' || e.code === 'Enter') {
                    e.stopPropagation();
                    e.preventDefault();

                    this.handleClickCouponCTA(e);
                }
            },

            attachCurrentCouponToForm(couponCodeOverride) {
                let couponCode = couponCodeOverride
                    ? couponCodeOverride
                    : this.form.couponCode?.value || '';

                if (this.$refs.couponRecurly) {
                    this.$refs.couponRecurly.value = couponCode;
                }

                return couponCode;
            },

            resetCouponFieldErrorMessage() {
                document.querySelector('.field-wrapper--coupon-code').classList.remove('error');
                this.$emit('resetCoupon');
            },

            handleChangePlanLink() {
                tracking.trackAction('trackChangeYourPlan', {
                    pageUrl: window.location.href,
                    pageType: 'svod_payment',
                    pickPlanType: this.planLabel,
                });
            },

            // START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
            ...mapActions('plan', ['filterPlans', 'updateSelectedPlan']),
            filterPlanAndUpdateSelected(action) {
                if (action) {
                    this.$store
                        .dispatch('plan/filterPlans', {
                            planType: Product.TYPE_ANNUAL,
                        })
                        .then(() => {
                            this.updateSelectedPlan(this.monthlySelectedAndAnnualAvailable?.[0]);
                        });
                } else {
                    this.$store
                        .dispatch('plan/filterPlans', {
                            planType: Product.TYPE_MONTHLY,
                        })
                        .then(() => {
                            this.updateSelectedPlan(this.annualSelectedAndMonthlyAvailable?.[0]);
                        });
                }
                const trackingAction =
                    this.isUpgradeAnnualToggle === true
                        ? 'trackPaymentAnnualPlanToggle'
                        : 'trackSelectNewBillingCadence';
                om.trackAction(trackingAction, {
                    pickPlanIsAnnual: action.toString(),
                    productOfferPeriod: this.plan?.trialString ?? '',
                    pickPlanSku: this.plan?.recurlyCode ?? '',
                    purchaseProductName: this.plan?.planTitle,
                });
            },
            // END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
        },

        // Lifecycle Hooks
        beforeCreate: function () {
            Object.assign(OrderSummaryConfig.hiddenInputList.plan, {
                value: this.$store.getters['plan/getCurrentPlan']?.recurlyCode,
            });
        },
        created() {
            this.setStartingStateOfCouponField();

            this.registrationCountry = this.$store.state.serverData.paymentForm?.country?.value
                ? this.$store.state.serverData.paymentForm?.country?.value.toLowerCase()
                : false;
            this.countryClass = `order-summary-${this.registrationCountry}`;
            this.featureAsterisk = this.$store.getters.featureIsActive(PRICE_WITH_ASTERISK);

            if (this.recurly) {
                this.getInternationalizedPricesUsingRecurly();
            }

            if (this.isAarpFlow()) {
                Object.assign(OrderSummaryConfig.inputList.couponCode, {
                    disabled: true,
                });
            }

            // START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
            // we need isUserOriginalPlanTypeMonthly flag to maintain local state, to show a/b test UI if user change plan on payment page
            if (this.upgradeAnnualToggle || this.upgradeAnnualPlanTiles) {
                this.isUserOriginalPlanTypeMonthly = this.plan.planType === Product.TYPE_MONTHLY;
            }
            // END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
        },
        watch: {
            recurly(newVal, oldVal) {
                if (oldVal === null && newVal !== null) {
                    this.getInternationalizedPricesUsingRecurly();
                }
            },
            plan(newVal) {
                const couponCode = this.attachCurrentCouponToForm(this.plan?.offerCouponCode ?? '');

                this.$emit('updateOrder', {
                    plan: this.plan.recurlyCode,
                    couponCode: couponCode,
                });
                this.getInternationalizedPricesUsingRecurly();
            },
            autoPromoChecked(newVal) {
                if (newVal === true) {
                    this.$nextTick(() => {
                        this.attachCurrentCouponToForm(
                            this.orderSummaryConfig?.inputList?.couponCode?.value,
                        );
                    });
                }
            },
            coupon(newVal) {
                if (newVal?.discountString || newVal?.trialString) {
                    this.automaticallyCloseCouponField();
                }

                utag_data.productDiscount = newVal?.discountString;
                utag_data.promoOffer = localStorage.getItem('promoOffer');
            },
        },
    };
</script>
