<template>
    <div class="plan-box__data" data-ci="plan-box-data">
        <MultiSubBoxDataHeader
            :content="header"
            :classes="[
                hasFeatureList && isMobileDevice
                    ? 'plan-box__line-divider is-mobile steps-header-message__blue'
                    : 'is-mobile plan-box__line-divider',
            ]"></MultiSubBoxDataHeader>

        <FeatureList :features="featureList"></FeatureList>

        <div v-if="plan.legal_disclaimer && stepClass === 'cadence'" class="plan__disclaimer">
            {{ plan.legal_disclaimer }}
        </div>
    </div>
</template>

<script>
    import FeatureList from 'aa/vue/components/multisubplanpicker/parts/FeatureList';
    import MultiSubBoxDataHeader from './MultiSubBoxDataHeader';
    import StepsHeaderMessage from 'aa/vue/components/StepsHeaderMessage';
    import couponsMixin from 'aa/vue/plugins/couponsMixin';
    import { getLocale } from 'services/LocalizationService';
    import { mapGetters } from 'vuex';
    import Breakpoints from 'helpers/breakpoints';
    import { pluralizeDuration } from 'aa/helpers/stringManipulation';
    import Product from 'aa/vue/models/Product';

    export default {
        name: 'MultiSubBoxData',
        mixins: [couponsMixin],
        data: function () {
            return {
                breakpoints: new Breakpoints(),
            };
        },
        props: {
            idx: {
                type: Number,
            },
            plan: {
                type: Object,
                default: function () {
                    return {
                        explainer_line_1: '',
                        explainer_line_2: '',
                        explainer_line_3: '',
                        explainer_line_4: '',
                        featured_line: '',
                        premium_features: '',
                        plan_pill: '',
                        legal_disclaimer: '',
                    };
                },
            },
            stepClass: {
                type: String,
                default: 'cadence',
            },
            recurlyCoupon: {
                type: [Object, String],
            },
            recurlyPricing: {
                type: Object,
            },
            showPromoDetails: {
                type: Boolean,
                default: false,
            },
            header: {
                type: String,
                default: '',
            },
            subheader: {
                type: String,
                default: '',
            },
        },
        components: {
            FeatureList,
            MultiSubBoxDataHeader,
            StepsHeaderMessage,
        },
        computed: {
            ...mapGetters(['featureIsActive']),
            couponObj() {
                return this.recurlyCoupon;
            },
            pricingObj() {
                return this.recurlyPricing;
            },
            /**
             * @returns {Array}
             */
            featureList() {
                const list = [];

                if (this.plan.featured_line) {
                    list.push({
                        class: 'featured',
                        description: this.plan.featured_line,
                    });
                }
                new Array(4).fill().forEach((value, index) => {
                    let key = `explainer_line_${index + 1}`;
                    const listItem = this.plan[key]?.trim();

                    if (listItem) {
                        list.push({
                            class: 'force_checkmark',
                            dataCi:
                                this.plan.plan?.planTier === Product.TIER_PREMIUM ||
                                this.plan.premium_features
                                    ? Product.TIER_PREMIUM
                                    : Product.TIER_STANDARD,
                            description: listItem,
                        });
                    }
                });

                if (this.showPromoDetails) {
                    if (this.couponObj && this.pricingObj) {
                        const hasPlanTrial = this.plan.plan.trial;
                        const isCouponFreeTrial = this.couponObj.discount.type === 'free_trial';
                        let promoCadenceDetail;

                        if (isCouponFreeTrial) {
                            promoCadenceDetail =
                                this.$store.state.plan.shared?.cadence_promo_details_dynamic_eft;
                        } else {
                            promoCadenceDetail = hasPlanTrial
                                ? this.$store.state.plan.shared
                                      ?.cadence_promo_details_dynamic_free_trial
                                : this.$store.state.plan.shared
                                      ?.cadence_promo_details_dynamic_no_free_trial;
                        }

                        list.push({
                            rawHtml: true,
                            class: 'featured',
                            description: this.replacePromoWildcards(
                                promoCadenceDetail,
                                hasPlanTrial,
                                isCouponFreeTrial,
                            ),
                        });
                    }
                }

                return list;
            },
            isMobileDevice() {
                return this.breakpoints.isMobilePortrait() || this.breakpoints.isMobileLandscape();
            },
            hasFeatureList() {
                return this.featureList?.length > 0;
            },
        },
        methods: {
            replacePromoWildcards(sentenceString, hasPlanTrial, isCouponFreeTrial) {
                const currentCadence =
                    this.$store.state.plan.shared?.[
                        this.plan.planType === Product.TYPE_MONTHLY
                            ? 'payment_per_month_short'
                            : 'payment_per_year_short'
                    ];

                const discountedPrice = hasPlanTrial
                    ? this.pricingObj.price.next.total
                    : this.pricingObj.price.now.total;
                const basePrice = this.pricingObj.price.base.plan.unit ?? '0';
                const currencySymbol = this.pricingObj.price.currency.symbol;
                const currencyCode = this.pricingObj.price.currency.code;

                const formattedDiscountedPrice = this.pricePrettyPrint(
                    discountedPrice,
                    currencySymbol,
                    currencyCode,
                    CBS.Registry.region.locale,
                );
                const formattedBasePrice = this.pricePrettyPrint(
                    basePrice,
                    currencySymbol,
                    currencyCode,
                    CBS.Registry.region.locale,
                );

                if (isCouponFreeTrial) {
                    sentenceString = sentenceString.replace(
                        '{Free Trial Duration}',
                        this.plan.plan.couponString,
                    );
                } else if (hasPlanTrial) {
                    sentenceString = sentenceString.replace(
                        '{Free Trial Duration}',
                        this.plan.plan.trialString,
                    );
                }

                if (!isCouponFreeTrial) {
                    sentenceString = sentenceString.replace(
                        '{Discounted Price}',
                        formattedDiscountedPrice,
                    );
                    sentenceString = sentenceString.replace('{Duration}', this.formatDuration());
                    sentenceString = sentenceString.replace('{Cadence}', currentCadence);
                }

                sentenceString = sentenceString.replace(
                    '{plan.couponString}',
                    this.plan.plan.couponString,
                );
                sentenceString = sentenceString.replace('{Subscription price}', formattedBasePrice);
                // {subscriptionPrice} is the right form for the wildcard as documented in confluence. Keeping the previous one for backward compatibility
                sentenceString = sentenceString.replace('{subscriptionPrice}', formattedBasePrice);
                sentenceString = sentenceString.replace('{Cadence}', currentCadence);

                return sentenceString;
            },
            formatDuration() {
                let amount;
                let { duration } = this.couponObj;

                if (duration === 'single_use') {
                    return this.singleUseTerm(this.plan.planType);
                }

                if (this.couponObj['temporal_amount']) {
                    amount = parseInt(this.couponObj['temporal_amount']);
                }
                if (this.couponObj['temporal_unit']) {
                    duration = this.couponObj['temporal_unit']
                        ? this.couponObj['temporal_unit']
                        : '';
                }

                duration = pluralizeDuration(amount, duration);

                return amount > 0 ? `${amount} ${getLocale(duration)}` : `${getLocale(duration)}`;
            },
        },
    };
</script>
