<template>
    <div class="multi-sub-plan" :class="{ '-switch': isSwitchPlan }">
        <steps-header
            v-bind="$props"
            :hasContent="hasHeaderContent"
            :overrideShouldDisplaySteps="isSwitchPlan"
            :overrideStepNumber="isSwitchPlan ? 1 : null"
            :overrideCountedSteps="isSwitchPlan ? 3 : null">
            <steps-header-message
                :header="isMobile ? '' : header"
                :subheader="isMobile ? '' : subheader"></steps-header-message>
        </steps-header>

        <div class="multi-sub-plan__flex -tier" v-if="getTiers" data-ci="tier-container">
            <v-app>
                <SlideGroup mandatory>
                    <v-slide-item v-for="(plan, idx) in getTiers" :key="idx" v-slot="{ toggle }">
                        <MultiSubBox
                            class="-tier"
                            :class="{
                                '-animate': isSelecting && active === idx,
                                '-switch-plan': isSwitchPlan,
                            }"
                            :idx="idx"
                            :plan="plan"
                            :is-active="active === idx"
                            :is-current="Boolean(plan.plan_is_current)"
                            :is-disabled="isSwitchPlan && disabledTiers.includes(plan.planTier)"
                            displayFeaturesList
                            @click="toggle"
                            @planSelected="handlePlanSelected"
                            :total-plans="getTiers.length"
                            pickAPlanTracking="tier"
                            :is-switch-plan="isSwitchPlan"
                            data-ci="tier-content"
                            :data-plan-card="`${plan.planTier}-plan`"
                            stepClass="tier"
                            :is-mobile="isMobile"
                            :max-plan-info-height="maxPlanInfoHeight"
                            @max-plan-info-height="setMaxPlanInfoHeight($event)"
                            :isPlanDescriptionEmpty="isEmptyTierDescriptionCopy"></MultiSubBox>
                    </v-slide-item>
                </SlideGroup>
            </v-app>
        </div>
        <div class="multi-sub-plan__legal">
            <MultiSubBoxLegal
                v-for="(plan, idx) in getTiers"
                :key="idx"
                :is-switch-plan="isSwitchPlan"
                :is-current="Boolean(plan.plan_is_current)"
                :plan="plan"
                stepClass="tier"></MultiSubBoxLegal>
        </div>
        <div class="multi-sub-plan__active">
            <MultiSubBoxData
                :key="activeTierIdx"
                :idx="activeTierIdx"
                :plan="activeTier"
                stepClass="tier"
                :is-active="true"
                :header="header"
                :subheader="subheader"></MultiSubBoxData>
            <div class="multi-sub-plan__spacer"></div>
            <div class="multi-sub-plan__legal-mobile">
                <LearnMoreLegal
                    v-if="mobileLegal"
                    :legal="mobileLegal"
                    stepClass="tier"></LearnMoreLegal>
            </div>
            <div :class="proceedButtonClasses" v-if="isMobile">
                <ButtonCta :disabled="!activeTier" :buttonConfig="getButtonConfig"></ButtonCta>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    import ButtonCta from 'atoms/ButtonCta';
    import MultiSubBox from 'aa/vue/components/multisubplanpicker/MultiSubBox';
    import MultiSubBoxData from 'aa/vue/components/multisubplanpicker/MultiSubBoxData';
    import LearnMoreLegal from 'aa/vue/components/multisubplanpicker/LearnMoreLegal';
    import SlideGroup from 'aa/vue/components/multisubplanpicker/parts/SlideGroup';
    import StepsHeader from 'aa/vue/components/StepsHeader';
    import StepsHeaderMessage from 'aa/vue/components/StepsHeaderMessage';
    import switchPlanMixin from 'aa/vue/components/multisubplanpicker/switchPlanMixin';
    import commonMixins from 'aa/vue/plugins/account/commonMixins';
    // START TEST PLOCTOPLUS-1298: google captcha
    import captchaMixin from 'aa/vue/plugins/captchaMixin';
    // END TEST PLOCTOPLUS-1298: google captcha
    import { mapState } from 'vuex';
    import Breakpoints from 'helpers/breakpoints';
    import MultiSubBoxLegal from 'aa/vue/components/multisubplanpicker/MultiSubBoxLegal';
    import { VSlideItem, VApp } from 'vuetify/lib';

    export default {
        name: 'Tier',
        mixins: [
            commonMixins,
            switchPlanMixin,
            // START TEST PLOCTOPLUS-1298: google captcha
            captchaMixin,
            // END TEST PLOCTOPLUS-1298: google captcha
        ],
        components: {
            StepsHeader,
            StepsHeaderMessage,
            ButtonCta,
            MultiSubBox,
            MultiSubBoxData,
            SlideGroup,
            LearnMoreLegal,
            MultiSubBoxLegal,
            VSlideItem,
            VApp,
        },
        props: {
            stepsHeader: {
                type: String,
                default: null,
            },
            stepsCounter: {
                type: String,
                default: null,
            },
        },
        data() {
            return {
                breakpoints: new Breakpoints(),
                btnDisplayText: '',
                // START TEST PLOCTOPLUS-1298: google captcha
                captchaSolved: false,
                captchaUseNonDefaultCallBack: true,
                captchaNonDefaultCallBack: this.submitOnClicked,
                captchaAction: 'FORM_SWITCH_PLAN',
                // END TEST PLOCTOPLUS-1298: google captcha
                isMobile: false,
                isSelecting: false,
                maxPlanInfoHeight: 0,
            };
        },
        mounted() {
            // START TEST PLOCTOPLUS-1298: google captcha
            if (this.isSwitchPlan) {
                this.$trackingService.trackState({ pageType: 'svod_switch-plan' });
                if (this.skipCadence) {
                    this.addCaptchaValidation();
                }
            }
            // END TEST PLOCTOPLUS-1298: google captcha

            window.addEventListener('resize', () => {
                requestAnimationFrame(() => {
                    this.updateBreakpoint();
                });
            });
            this.updateBreakpoint();
        },
        methods: {
            ...mapActions('plan', ['setPlanTier']),
            handlePlanSelected(data) {
                const { idx, submit } = data;
                this.active = idx;
                if (submit) {
                    if (this.isSwitchPlan) {
                        if (this.skipCadence && this.isEnhancedPaymentSummaryEnabled) {
                            this.isSelecting = true;

                            setTimeout(() => {
                                this.isSelecting = false;
                                this.$router.push({ name: 'AccountSummary' });
                            }, 500);
                        } else if (this.skipCadence) {
                            this.isSelecting = true;
                            setTimeout(() => {
                                this.isSelecting = false;
                                this.submitOnClicked();
                            }, 500);
                        } else {
                            this.isSelecting = true;

                            setTimeout(() => {
                                this.isSelecting = false;
                                this.$router.push({ name: 'AccountCadence' });
                            }, 500);
                        }
                    } else {
                        // Purchase flow.
                        this.$router.push(this.$store.getters['flow/getNextStep']);
                    }
                }
            },
            updateBreakpoint() {
                this.isMobile =
                    this.breakpoints.isMobilePortrait() || this.breakpoints.isMobileLandscape();
            },
            setMaxPlanInfoHeight(height) {
                this.maxPlanInfoHeight = Math.max(this.maxPlanInfoHeight, height);
            },
        },
        computed: {
            ...mapGetters('plan', [
                'getTiers',
                'getPlanTier',
                'activeTier',
                'getLegals',
                'getPromoFields',
                'isSwitchPlan',
                'skipCadence',
                'currentPlan',
                'isEmptyTierDescriptionCopy',
                'validPromoExists',
            ]),
            ...mapState(['promo']),
            ...mapState('plan', ['disabledTiers']),
            ...mapState('user', ['isSubscriber', 'isRegistered', 'isExSubscriber']),
            ...mapGetters(['featureIsActive']),
            isEnhancedPaymentSummaryEnabled() {
                return this.$store.state.serverData.enhancedPaymentSummaryEnabled;
            },
            hasHeaderContent() {
                return !this.isMobile && !this.isSwitchPlan;
            },
            activeTierIdx() {
                return this.activeTier?.idx;
            },
            active: {
                get() {
                    const activeTier = this.getTiers.findIndex(
                        (tier) => this.getPlanTier === tier.planTier,
                    );
                    return activeTier;
                },
                set(index) {
                    if (index >= 0 && index < this.getTiers.length) {
                        this.setPlanTier(this.getTiers[index].planTier);
                    } else {
                        this.setPlanTier(null);
                    }
                },
            },
            defaultNoPromoPA() {
                const promoFields = this.getPromoFields;
                return promoFields?.find((promoFieldPA) =>
                    promoFieldPA.tags.includes('default_nopromo'),
                );
            },
            promoPA() {
                const promoFields = this.getPromoFields;
                return promoFields?.find((promoFieldPA) => promoFieldPA.tags.includes('promo'));
            },
            isCMSPromo() {
                return (
                    this.validPromoExists && this.$store.getters.getServerDataByKey('isCMSPromo')
                );
            },
            isURLPromo() {
                return this.validPromoExists && !this.isCMSPromo;
            },
            header() {
                if (this.isSwitchPlan) {
                    return '';
                }

                const headerTitle = this.isMobile ? 'shortheader' : 'header';

                if (this.isURLPromo) {
                    return this.defaultNoPromoPA?.[`tier_${headerTitle}_urlpromo`] ?? '';
                }

                const promoKey = `tier_${headerTitle}promo_${this.userState}_web`;

                if (this.isCMSPromo && this.promoPA && Boolean(this.promoPA[promoKey])) {
                    return this.promoPA[promoKey];
                }

                const defaultKey = `tier_${headerTitle}_default_${this.userState}`;
                const headerValue = this.defaultNoPromoPA?.[defaultKey] ?? '';

                return headerValue;
            },
            subheader() {
                if (this.isSwitchPlan || this.isMobile) {
                    return '';
                }

                if (this.isURLPromo) {
                    return this.defaultNoPromoPA?.['tier_subheader_urlpromo'] ?? '';
                }

                const defaultKey = `tier_subheader_default_${this.userState}`;
                const promoKey = `tier_subheaderpromo_${this.userState}_web`;

                if (this.isCMSPromo && this.promoPA && Boolean(this.promoPA[promoKey])) {
                    return this.promoPA[promoKey];
                }

                return this.defaultNoPromoPA?.[defaultKey] ?? '';
            },
            userState() {
                if (this.isExSubscriber) {
                    return 'exsub';
                } else if (this.isSubscriber) {
                    return 'sub';
                } else if (this.isRegistered) {
                    return 'reg';
                }
            },
            mobileLegal: function () {
                /**
                 * This will be reevaluated everytime a tier is selected
                 * On Mobile, we only display one legal depending on which tier is selected
                 */
                return this.getLegals?.find((legal) => {
                    return legal.tags.find((tag) =>
                        tag.includes(`plan_${this.activeTier?.planTier}`),
                    );
                });
            },
            getButtonConfig: function () {
                return {
                    styleClass: `button primary ${!this.activeTier ? 'disabled' : ''}`,
                    displayText:
                        this.activeTier?.plan_button ||
                        this.getTiers?.[0]?.plan_button ||
                        btnDisplayText,
                    tabIndex: '0',
                    event: (event) => {
                        event.stopPropagation();
                        const args = {
                            idx: this.getTiers.findIndex(
                                (tier) => this.activeTier?.planTier === tier.planTier,
                            ),
                            submit: true,
                        };
                        this.handlePlanSelected(args);
                    },
                };
            },
            proceedButtonClasses() {
                return [
                    'multi-sub-plan__proceed-button',
                    { '-gradient': this.legal?.learn_more_copy.length > 200 },
                ];
            },
        },
    };
</script>
