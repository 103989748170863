<template>
    <div
        v-if="showLegal"
        class="learn-more-legal learn-more-legal__margin"
        data-ci="learn-more-legal">
        <button
            v-if="showLearnMorePill"
            v-on:click="openLegalModal"
            class="learn-more-legal__button">
            <i class="learn-more-legal__button-icon"></i>
            <p class="learn-more-legal__button-text">{{ legal.learn_more_pill }}</p>
        </button>
        <p class="learn-more-legal__copy" v-html="getLearnMoreCopy()"></p>
    </div>
</template>

<script>
    import modalMixin from 'aa/vue/plugins/modalMixin';
    import Breakpoints from 'helpers/breakpoints';
    import { tracking } from 'services/Tracking';
    import { mapGetters } from 'vuex';
    import Product from 'aa/vue/models/Product';

    export default {
        name: 'LearnMoreLegal',
        mixins: [modalMixin],
        data: function () {
            return {
                breakpoints: new Breakpoints(),
            };
        },
        props: {
            legal: {
                type: Object,
                default() {
                    return {
                        learn_more_pill: '',
                        learn_more_copy: '',
                        learn_more_copy_annual: '',
                        legal_copy_header: '',
                        legal_copy_mobile_monthly: '',
                        legal_copy_mobile_annual: '',
                        legal_copy_desktop_monthly: '',
                        legal_copy_desktop_annual: '',
                        legal_copy_ott: '',
                    };
                },
            },
            planType: {
                type: String,
                default() {
                    return Product.TYPE_MONTHLY;
                },
            },
            stepClass: {
                type: String,
                default: 'cadence',
            },
        },
        methods: {
            openLegalModal() {
                const legalData = {
                    header: this.legal.legal_copy_header,
                    copy: this.getLegalCopy(),
                };

                this.openModal('LegalOverlayModal', legalData);
                this.$store.commit('modal/setAdditionalClasses', 'legal-overlay-modal-wrapper');
                const trackingParams = {
                    ctaText: this.legal.learn_more_pill.trim(),
                    pageType: 'svod_pickaplan',
                    screenName: '/pick-a-plan/',
                };
                tracking.trackAction('trackLegalModalView', trackingParams);
            },
            getLegalCopy() {
                if (this.stepClass === 'tier') {
                    return this.legal['legal_copy_desktop'];
                } else {
                    const currentCadence =
                        this.planType === Product.TYPE_MONTHLY ? '_monthly' : '_annual';

                    return this.legal[`legal_copy_desktop${currentCadence}`];
                }
            },
            getLearnMoreCopy() {
                if (this.stepClass === 'tier' || this.planType === 'monthly') {
                    return this.legal?.learn_more_copy;
                }
                return this.legal?.learn_more_copy_annual;
            },
        },
        computed: {
            ...mapGetters(['featureIsActive']),
            showLearnMorePill() {
                return this.legal.learn_more_pill && this.legal.learn_more_pill !== '';
            },
            showLegal() {
                return this.showLearnMorePill || this.legal.learn_more_copy;
            },
        },
    };
</script>
